import React                                         from 'react';
import { navigate }                                  from 'gatsby';
import { Divider }                                   from '../../lib/components/divider/divider';
import { Wrapper }                                   from '../../lib/components/wrapper/wrapper.component';
import { SiteLayout }                                from '../../lib/container/site-layout/site-layout';
import { TopBar }                                    from '../../lib/container/top-bar/top-bar';
import { categories, Publications, PublicationType } from './publications';

type PropsType = {};

export const PublicationPage = (props: {type?: PublicationType}) => {
    const meta = categories.find((item) => item.key === props.type);
    return (
        <SiteLayout>
            <Wrapper fullWidth style={ { minWidth: '100%' } }>
                <TopBar line onClick={ () => navigate('/') } />
                <Wrapper>
                    <Wrapper radius="lg" align="left" padding="xl" style={ { paddingLeft: 0 } } fullWidth>
                        <h1 style={ {
                            fontSize:      '12vw',
                            lineHeight:    1,
                            marginBottom:  '4rem',
                            maxWidth:      '100vw',
                            textTransform: 'uppercase',
                            textAlign:     'left',
                            alignItems:    'center'
                        } }
                        >
                            { meta.title }
                        </h1>

                    </Wrapper>
                </Wrapper>
            </Wrapper>
            <Publications type={ props.type } />
        </SiteLayout>
    );
};
